@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@400;600;700&family=Work+Sans:wght@400;600;700;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

:root {
  --darkBlue: #100f4a;
  --mediumBlue: #9f9deb;
  --lightBlue: #86dede;
  --yellow: #f9ce34;
  --orange: #fb7d3e;
  --offWhite: #f8f8f8;
  --cabin: "Cabin", sans-serif;
  --workSans: "Work Sans", sans-serif;
  --transition: all 0.3s ease;
  --shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

body {
  font-family: "Cabin", sans-serif;
  font-family: var(--cabin);
  background: #f8f8f8;
  background: var(--offWhite);
  font-weight: 600;
}

h1 {
  font-family: "Work Sans", sans-serif;
  font-family: var(--workSans);
  font-weight: 800;
}

/* Navbar */
.nav {
  width: 100vw;
  background: #100f4a;
  background: var(--darkBlue);
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  z-index: 999;
}

.nav-container {
  max-width: 1160px;
  margin: 0 auto;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;
}

.nav-logo {
  margin-left: 30px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.nav-toggle {
  background: transparent;
  border: none;
  color: #86dede;
  color: var(--lightBlue);
  font-size: 24px;
  margin-right: 30px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.nav-link a {
  color: #f8f8f8;
  color: var(--offWhite);
  text-decoration: none;
  padding: 15px 30px;
  display: block;
  border-top: 1px solid rgba(159, 157, 235, 0.4);
}

.nav-links-container {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  transition: var(--transition);
}

@media screen and (min-width: 768px) {
  .nav-toggle {
    display: none;
  }
  .nav {
    padding: 0 60px;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
  }
  .nav-links-container {
    height: auto !important;
    display: grid;
    align-items: center;
    overflow: visible;
  }
  .nav-links {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
  }
  .nav-logo {
    margin: 0;
  }
  .nav-link a {
    font-size: 16px;
    color: #f8f8f8;
    color: var(--offWhite);
    text-decoration: none;
    padding: 0;
    border: none;
    position: relative;
    transition: all 0.3s ease;
    transition: var(--transition);
  }
  .nav-link a:hover {
    opacity: 0.9;
  }
  .nav-link a::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 3px;
    -webkit-transform-origin: center;
            transform-origin: center;
    background: #f8f8f8;
    background: var(--offWhite);
    border-radius: 4px;
    transition: all 0.3s ease;
    transition: var(--transition);
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  .nav-link a:hover::after {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Hero */
.hero-section {
  padding: 30px;
  padding-top: calc(70px + 60px);
  position: relative;
}

.hero-container {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.hero-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-img {
  display: grid;
  place-items: center;
}

.hero-image img {
  width: 100%;
  max-width: 260px;
}

.hero-title {
  width: 100%;
  max-width: 236px;
}

.hero-subtitle {
  color: #100f4a;
  color: var(--darkBlue);
  margin-top: 15px;
  font-size: 18px;
  text-align: center;
  max-width: 260px;
  font-weight: 700;
}

.hero-sub-subtitle {
  color: #100f4a;
  color: var(--darkBlue);
  margin-top: 30px;
  max-width: 466px;
  line-height: 1.5em;
  font-size: 16px;
  opacity: 0.95;
  text-align: justify;
}

.hero-btn {
  padding: 16px 50px;
  font-weight: 600;
  color: #f8f8f8;
  color: var(--offWhite);
  background: #100f4a;
  background: var(--darkBlue);
  border-radius: 4px;
  border: none;
  margin-top: 30px;
  transition: all 0.3s ease;
  transition: var(--transition);
  cursor: pointer;
}

.hero-btn:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .hero-image img {
    max-width: 561.16px;
  }
  .hero-info {
    align-items: flex-start;
  }
  .hero-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 60px;
    padding-top: 70px;
    padding-bottom: 0px;
  }
  .hero-title {
    max-width: 464px;
  }
  .hero-subtitle {
    max-width: 464px;
    font-size: 28px;
    text-align: left;
  }
  .hero-container {
    flex-direction: row-reverse;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
  }
  .hero-btn {
    font-size: 18px;
  }
}

/* Footer */
.footer {
  background: #100f4a;
  background: var(--darkBlue);
  color: #f8f8f8;
  color: var(--offWhite);
  padding: 30px;
}

.footer a {
  color: #f8f8f8;
  color: var(--offWhite);
  text-decoration: none;
}

.footer-logo {
  max-width: 134px;
}

.footer-container {
  max-width: 1160px;
  margin: 0 auto;
}

.footer-text {
  margin-top: 15px;
  max-width: 260px;
}

.footer-top {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 30px;
  gap: 30px;
}

.footer-divider {
  width: 100%;
  height: 1px;
  background: #9f9deb;
  background: var(--mediumBlue);
  margin-top: 30px;
}

.footer-bottom {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.footer-btn-container {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

.footer-instagram {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: #f8f8f8;
  background: var(--offWhite);
  transition: all 0.3s ease;
  transition: var(--transition);
}

.footer-instagram:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0.8;
}

.footer-instagram svg {
  color: #100f4a;
  color: var(--darkBlue);
  font-size: 22px;
}

.footer-link {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.footer-link:hover {
  opacity: 0.9;
}

.footer-link::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transform-origin: center;
          transform-origin: center;
  background: #f8f8f8;
  background: var(--offWhite);
  border-radius: 4px;
  transition: all 0.3s ease;
  transition: var(--transition);
  -webkit-transform: scale(0);
          transform: scale(0);
}

.footer-link:hover::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 60px;
  }
  .footer-top {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footer-links {
    flex-direction: row;
    grid-gap: 45px;
    gap: 45px;
    justify-content: space-between;
  }
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* Products */

.section-title {
  display: flex;
  justify-content: center;
}

.section-title h1 {
  font-weight: 800;
  font-family: "Work Sans", sans-serif;
  font-family: var(--workSans);
  letter-spacing: 0.06em;
  font-size: 36px;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  color: #100f4a;
  color: var(--darkBlue);
}

.section-title h1::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background: #f9ce34;
  background: var(--yellow);
}

@media screen and (min-width: 768px) {
  .section-title h1 {
    font-size: 48px;
  }
}

.products-section {
  padding: 30px;
}

.products-container {
  max-width: 1160px;
  margin: 0 auto;
}

.product-cards-container {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.product-card {
  width: 75vw;
  height: 75vw;
  max-width: 400px;
  max-height: 400px;
  display: grid;
  place-items: center;
  padding: 30px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  position: relative;
  border-radius: 4px;
}

.product-img {
  width: 60%;
}

.product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: grid;
  place-items: center;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.product-button {
  color: #f8f8f8;
  color: var(--offWhite);
  padding: 12px 20px;
  border: 2px solid #f8f8f8;
  border: 2px solid var(--offWhite);
  border-radius: 40px;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.download-card:hover > .product-card .product-button {
  background: rgba(255, 255, 255, 0.8);
  color: #100f4a;
  color: var(--darkBlue);
}

@media screen and (min-width: 768px) {
  .products-section {
    padding: 60px;
    padding-bottom: 120px;
  }
  .product-card {
    font-size: 20px;
  }
}

@media screen and (min-width: 900px) {
  .product-cards-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .product-card {
    width: 250px;
    height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .product-card {
    width: 300px;
    height: 300px;
  }
}

@media screen and (min-width: 1160px) {
  .product-card {
    width: 350px;
    height: 350px;
  }
}

/* Downloads */
a {
  text-decoration: none;
}

.downloads-section {
  padding-top: calc(70px + 60px);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
  position: relative;
}

.downloads-container {
  max-width: 1160px;
  margin: 0 auto;
}

.download-title {
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  color: #100f4a;
  color: var(--darkBlue);
  font-size: 24px;
  position: relative;
  display: inline-block;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.download-title::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transform-origin: center;
          transform-origin: center;
  background: #100f4a;
  background: var(--darkBlue);
  border-radius: 4px;
  transition: all 0.3s ease;
  transition: var(--transition);
  -webkit-transform: scale(0);
          transform: scale(0);
}

.download-card:hover > .download-title::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.download-card .product-card {
  transition: all 0.3s ease;
  transition: var(--transition);
}

.download-card:hover > .product-card {
  background: rgba(0, 0, 0, 0.02);
}

@media screen and (min-width: 768px) {
  .downloads-section {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

/* Video */

.video-section {
  padding-top: calc(70px + 60px);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
}

.video-container {
  max-width: 1170px;
  margin: 0 auto;
}

.video-frame {
  margin-top: 30px;
  display: grid;
  place-items: center;
}

.video-frame iframe {
  min-height: 400px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .video-section {
    padding-left: 60px;
    padding-right: 60px;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .video-container {
    width: 100%;
  }
  .video-frame iframe {
    min-height: 60vh;
    width: 100%;
  }
}

/* Product */
.product-section {
  padding-top: calc(70px + 60px);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
  overflow: hidden;
}

.product-container {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.product-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}

.arm-robot-preview-container {
  margin-bottom: 80px;
}

.product-preview-large {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  width: 260px;
  height: 260px;
  display: grid;
  place-items: center;
  cursor: pointer;
  -webkit-animation: pulse;
          animation: pulse;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.product-preview-large img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.product-preview-small-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.product-preview-small {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  padding: 5px;
  transition: all 0.3s ease;
  transition: var(--transition);
  cursor: pointer;
}

.product-preview-small:hover {
  background: hsl(0, 0%, 95%);
}

.product-preview-large:hover {
  background: hsl(0, 0%, 95%);
}

.product-preview-small img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.product-tabs {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  background: #afe9e9;
  border-radius: 100px;
}

.product-tab {
  padding: 20px;
  border-radius: 100px;
  font-family: "Cabin", sans-serif;
  font-family: var(--cabin);
  font-weight: 600;
  color: #100f4a;
  color: var(--darkBlue);
  border: none;
  background: #afe9e9;
  width: calc(100% / 3);
  cursor: pointer;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.product-tab:hover {
  opacity: 0.8;
  background: #86dede;
  background: var(--lightBlue);
}

.product-tab.active {
  background: #86dede;
  background: var(--lightBlue);
}

.product-info-container {
  position: relative;
}

.product-info {
  margin-top: 15px;
}

.product-info p {
  font-weight: 600;
  font-size: 16px;
  line-height: 163.2%;
  color: #100f4a;
  color: var(--darkBlue);
  padding-bottom: 80px;
  -webkit-animation: fadeInRight;
          animation: fadeInRight;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.product-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.product-button-relative {
  position: relative;
}

.shop-icon-container {
  position: absolute;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  bottom: 0;
  right: 0;
  transition: all 0.3s ease;
  transition: var(--transition);
  -webkit-transform: scale(0);
          transform: scale(0);
}

.shop-icon-container.show {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.btn-beli {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 50px;
  background: #100f4a;
  background: var(--darkBlue);
  color: #f8f8f8;
  color: var(--offWhite);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-beli:hover {
  border-radius: 20px;
  background: hsl(241, 66%, 37%);
}

.btn-beli.hide {
  -webkit-transform: translateX(500%);
          transform: translateX(500%);
}

.btn-video {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(100%) translateY(30px);
          transform: translateX(-50%) translateY(100%) translateY(30px);
  border: none;
  background: hsl(20, 96%, 51%);
  color: #f8f8f8;
  color: var(--offWhite);
  padding: 18px 40px;
  font-size: 16px;
  min-width: 200px;
  transition: all 0.3s ease;
  transition: var(--transition);
  cursor: pointer;
  font-weight: 600;
}

.btn-video:hover {
  border-radius: 20px;
  background: hsl(20, 96%, 61%);
}

.shop-icon img {
  width: 45px;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.shop-icon img:hover {
  opacity: 0.8;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

@media screen and (min-width: 768px) {
  .product-preview-large {
    width: 240px;
    height: 240px;
  }
  .product-preview-small {
    width: calc(220px / 3);
    height: calc(220px / 3);
  }
  .product-section {
    padding-left: 60px;
    padding-right: 60px;
    min-height: 100vh;
    display: flex;
  }
  .product-container {
    flex-direction: row;
    align-items: center;
  }
  .product-container {
    grid-gap: 30px;
    gap: 30px;
  }
  .product-preview-container {
    flex-direction: row;
  }
  .product-preview-small-container {
    flex-direction: column;
  }
  .product-container .section-title {
    justify-content: flex-start;
  }
  .product-container .section-title h1 {
    text-align: left;
  }
  .btn-video {
    left: 0;
    -webkit-transform: translateX(0) translateY(78px);
            transform: translateX(0) translateY(78px);
  }
}

@media screen and (min-width: 900px) {
  .product-container {
    grid-gap: 60px;
    gap: 60px;
  }
  .product-preview-large {
    width: 320px;
    height: 320px;
  }
  .product-preview-large img {
    width: 280px;
    height: 280px;
    object-fit: contain;
  }
  .product-preview-small {
    width: calc(300px / 3);
    height: calc(300px / 3);
  }
  .product-tab {
    font-size: 16px;
  }
  .product-info p {
    font-size: 18px;
  }
  .btn-beli {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .product-preview-large {
    width: 360px;
    height: 360px;
  }
  .product-preview-large img {
    width: 320px;
    height: 320px;
    object-fit: contain;
  }
  .product-preview-small {
    width: calc(340px / 3);
    height: calc(340px / 3);
  }
  .product-tab {
    font-size: 18px;
  }
  .shop-icon img {
    width: 69px;
    height: 69px;
  }
  .product-info p {
    padding-bottom: 200px;
  }
  .btn-beli {
    height: 60px;
    width: 200px;
    font-size: 18px;
  }
  .btn-video {
    font-size: 18px;
    -webkit-transform: translateY(calc(100% + 30px));
            transform: translateY(calc(100% + 30px));
  }
}

/* Modal */
.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: grid;
  place-items: center;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 0.3s ease;
  transition: var(--transition);
}

.modal-overlay.show-modal {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.modal-card {
  width: 80%;
  height: 80%;
  background: #f8f8f8;
  background: var(--offWhite);
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  position: relative;
  display: grid;
  place-items: center;
}

.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  background: none;
  border: none;
  font-size: 32px;
  color: #fb7d3e;
  color: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.modal-close:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.modal-img {
  position: absolute;
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.modal-card iframe {
  width: 80%;
  height: 80%;
}

/* Alert */
.alert {
  position: fixed;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(0);
          transform: translateX(-50%) scale(0);
  transition: all 0.3s ease;
  transition: var(--transition);
}

.alert.show-alert {
  -webkit-transform: translateX(-50%) scale(1);
          transform: translateX(-50%) scale(1);
}

.alert p {
  padding: 15px;
  background: #fb7d3e;
  background: var(--orange);
  color: #f8f8f8;
  color: var(--offWhite);
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
}

/* Software */
.softwares-section {
  padding: 30px;
}

.softwares-subtitle {
  text-align: justify;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 1.5em;
  color: #100f4a;
  color: var(--darkBlue);
}

.softwares-container {
  max-width: 1160px;
  margin: 0 auto;
}

.software-cards-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.software-card {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
}

.sc-title {
  text-align: center;
  font-size: 32px;
  color: #100f4a;
  color: var(--darkBlue);
}

.sc-contents {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 30px;
  color: #100f4a;
  color: var(--darkBlue);
}

.sc-contents p {
  text-align: justify;
}

.sc-download-btn {
  display: block;
  background: #100f4a;
  background: var(--darkBlue);
  text-align: center;
  color: #f8f8f8;
  color: var(--offWhite);
  padding: 20px 0px;
  border-radius: 10px;
  margin-top: 60px;
  transition: all 0.3s ease;
  transition: var(--transition);
}

.sc-download-btn:hover {
  opacity: 0.8;
}

.ardublockly-img {
  max-width: 602px;
  width: 100%;
  margin: 0 auto;
}

.usb-img {
  max-width: 413px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .softwares-section {
    padding: 60px;
    padding-bottom: 120px;
  }
  .software-cards-container {
    margin-top: 60px;
    grid-gap: 60px;
    gap: 60px;
  }
  .softwares-subtitle {
    font-size: 20px;
    text-align: center;
  }
  .sc-title {
    font-size: 48px;
  }
  .sc-contents p {
    font-size: 20px;
  }
  .sc-contents img {
    padding-top: 15px;
  }
  .software-card {
    padding: 60px;
  }
  .sc-download-btn {
    display: inline-block;
    padding: 20px 80px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

/* Mouse thing from codepen */
@media screen and (min-width: 768px) and (min-height: 800px) {
  .mouse {
    width: 26px;
    height: 42px;
    border: 2px solid #100f4a;
    border: 2px solid var(--darkBlue);
    border-radius: 24px;
    position: absolute;
    bottom: 45px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .mouse:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -2px;
    background-color: #100f4a;
    background-color: var(--darkBlue);
    border-radius: 100%;
    width: 4px;
    height: 4px;
    opacity: 1;
    -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    -webkit-animation: scroll 1s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
    animation: scroll 1s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  }

  @-webkit-keyframes scroll {
    0%,
    20% {
      -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
      -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
      transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5)
        translateZ(0px);
      transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
      opacity: 0.01;
    }
  }

  @keyframes scroll {
    0%,
    20% {
      -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
      -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
      transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5)
        translateZ(0px);
      transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
      opacity: 0.01;
    }
  }
}

